<!-- 前区尾数走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1629609108732" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2399" width="45" height="45">
                        <path d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z" fill="#322C2B"
                            p-id="2400"></path>
                        <path d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
                            fill="#0C7CBE" p-id="2401"></path>
                        <path d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
                            fill="#EFB918" p-id="2402"></path>
                        <path d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
                            fill="#14934A" p-id="2403"></path>
                        <path d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z"
                            fill="#D7282A" p-id="2404"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国体育彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>大 乐 透 前 区 -- 尾 数 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <!-- <th rowspan="2" class="ranks_a1">星期</th> -->
                        <th colspan="10" class="ranks_b">前区--尾数走势图</th>
                        <th colspan="10" class="ranks_b">前区第一位走势图</th>
                        <th colspan="10" class="ranks_b">前区第二位走势图</th>
                        <th colspan="10" class="ranks_b">前区第三位走势图</th>
                        <th colspan="10" class="ranks_b">前区第四位走势图</th>
                        <th colspan="10" class="ranks_b">前区第五位走势图</th>
                      


                    </tr>
                    <tr>
                        <th class="ranks_c" v-for="(item,i) in 10" :key="i">{{item-1}}</th>
                        <th class="ranks_c" v-for="(item,i) in 10" :key="i">{{item-1}}</th>
                        <th class="ranks_c" v-for="(item,i) in 10" :key="i">{{item-1}}</th>
                        <th class="ranks_c" v-for="(item,i) in 10" :key="i">{{item-1}}</th>
                        <th class="ranks_c" v-for="(item,i) in 10" :key="i">{{item-1}}</th>
                        <th class="ranks_c" v-for="(item,i) in 10" :key="i">{{item-1}}</th>


                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in dlt" :key='x'>
                        <td>{{item2.expect}}</td>
                        <!-- <td>{{}}</td> -->

                        <td class="b-red" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == item1 ? 'q-red':'no'" v-for="(item1 ,j) in red0[x]" :key='j'>
                                {{item == item1 ?item:'' }}
                            </div>
                        </td>
                        <td class="b-lan" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[0])%10? 'q-lan' : ''">
                                {{item == (item2.red[0])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="b-red" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[1])%10? 'q-red' : ''">
                                {{item == (item2.red[1])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="b-lan" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[2])%10? 'q-lan' : ''">
                                {{item == (item2.red[2])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="b-red" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[3])%10? 'q-red' : ''">
                                {{item == (item2.red[3])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="b-lan" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[4])%10? 'q-lan' : ''">
                                {{item == (item2.red[4])%10 ?item:'' }}
                            </div>
                        </td>

                    </tr>

                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
     import mitt from '@/js/mitt.js'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'red-tail',
        components: {

        },
        data() { // 数据源
            return {
                red: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                red0:[]
            
            }
        },
        mounted() {
            this.mred()
            this.find()
        },
        computed: {
            dlt() {
                return this.$store.state.dlt;
            },
        },
        methods: { // 函数体部分，js的主要逻辑控制  
            find() {
                document.documentElement.scrollTop = 0;
                if (this.dlt == '') {
                    mitt.emit('dlt');
                }
            },    
            mred() {
                this.red0=[]
                for (var i = 0; i < this.dlt.length; i++) {
                    var arr = []
                    // 前区号码尾数去重
                    for (var j = 0; j < this.dlt[i].red.length; j++) {
                        if(arr.indexOf(this.dlt[i].red[j]%10)==-1){
                            arr.push(this.dlt[i].red[j]%10)
                        }
                       
                    }
                    this.red0.push(arr)
                  //  console.log(arr)
                }
            },         
        },
        props: {
        },
        watch:{
            dlt(){
                this.mred()
            }
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: rgb(224, 202, 5);
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 60px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    .b-red {
        height: 23px;
        background-color: rgb(221, 201, 171);

    }

    .b-lan {
        background-color: rgb(184, 200, 230);
    }

    .q-red {
        background-color: rgb(158, 29, 147);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: rgb(212, 164, 3);
        color: #fff;
        border-radius: 15px;
    }

    .no {
        display: none;
    }
</style>